// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import "@ag-grid-community/core/dist/styles/ag-theme-material.css";
@import "@ag-grid-community/core/dist/styles/ag-grid.css";

@import './styles/color_palette';
@import './styles/ag-grid';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rm-rar-table-maintenance-frontend-primary: mat.define-palette($light-primary-palette);
$rm-rar-table-maintenance-frontend-accent: mat.define-palette($light-accent-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$rm-rar-table-maintenance-frontend-warn: mat.define-palette($warn-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$rm-rar-table-maintenance-frontend-theme: mat.define-light-theme((color: (primary: $rm-rar-table-maintenance-frontend-primary,
        accent: $rm-rar-table-maintenance-frontend-accent,
        warn: $rm-rar-table-maintenance-frontend-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($rm-rar-table-maintenance-frontend-theme);

@import 'unity/css/em-unity-1.7.1.css';


html,
body {
  height: 100%;
  width: 100%;
}

:root {
  font-family: "EMprintRegular", "sans-serif" !important;
}


/******************************/
/*** Adjust Material Styles ***/
/******************************/

h1,
h2,
h3,
h4,
h5,
mat-panel-title,
mat-label,
mat-select,
mat-option {
  font-family: "EMprintRegular", "sans-serif" !important;
}

.mat-card,
.mat-button,
.mat-calendar {
  font-family: "EMprintRegular", "sans-serif" !important;
}

.mat-form-field {
  font-family: "EMprintRegular", "sans-serif" !important;
}

/******************************/

/******************************/
/**** Adjust Unity Styles *****/
/******************************/

@media (min-width: 62em) {
  .em-c-tree {
    display: block;
    height: unset;
    max-width: 30rem;
  }
}

/******************************/

/******************************/
/*** Adjust AG-Grid Styles ****/
/******************************/

.ag-theme-material {
  --ag-checkbox-checked-color: #013554;
}

/******************************/

/******************************/
/********* App Styles *********/
/******************************/
.editable-column {
  .ag-header-cell-label::after {
    content: "edit_square";
    font-family: "Material Icons";
    margin-left: 1rem;
    color: var(--tblHeaderClr);
    font-weight: normal;
    font-size: medium;
  }
}

.invalid-cell {
  border: 1px solid map-get($rm-rar-table-maintenance-frontend-warn,"800");
}

/******************************/