:root {
  --bodyBg: #f5f5f5;
  --bodyClr: #000;
  --mainHeaderBg: #013554;
  --mainHeaderClr: #FFF;
  --tblBg: #FFF;
  --tblClr: rgba(0, 0, 0, 0.87);
  --tblHeaderBg: #4a4c4c;
  --tblHeaderClr: #FFF;
  --lSidenavBg: #FFF;
  --lSidenavClr: rgba(0, 0, 0, 0.87);
  --rSidenavBg: #FFF;
  --rSidenavClr: rgba(0, 0, 0, 0.87);
  --evenBg: #F6F6F6;
  --evenClr: #000;
  --oddBg: #FFF;
  --oddClr: #000;
  --rowHoverBg: #FFD24D;
  --rowHoverClr: #FFF;
  --columnBg: #eee;
  --columnHoverBg: rgba(251, 197, 49, 0.30);
  --columnHoverClr: #FFF;
  --borderClr: #e2e2e2;
  --footerBg: #696969;
  --footerClr: #FFFFFF;
  --scrollbarBdr: #d5d5d5;
  --scrollbarTrack: #eeeeee;
  --scrollbarThumb: #7D7F7F;
  --ag-checkbox-checked-color: #013554;
}


$ag-font-family: EMprintRegular;
$ag-row-height: null;
$ag-row-height: 36px !global;

// @import "ag-grid-community/dist/styles/ag-grid.css";
// @import "ag-grid-community/dist/styles/ag-theme-material.css";

ag-grid-angular {
  font-family: EMprintRegular;

  &.filter-panel-open {
    width: calc(100% - 1rem);
  }

  height: calc(100% - 2rem);

  .ag-numeric-header .ag-header-cell-label {
    flex-direction: row !important;
  }

  .ag-header-cell-text {
    color: white;
  }

  &.highlight {
    .ag-header {

      .ag-header-cell,
      .ag-cell {
        &.ag-column-hover {
          color: var(--columnHoverClr);
        }
      }
    }

    .ag-row-hover:not(.summation-row-footer) {
      background-color: var(--rowHoverBg) !important;
      color: var(--rowHoverClr) !important;
    }

    .ag-column-hover {
      background-color: var(--columnHoverBg) !important;
    }

    .ag-row-hover,
    .ag-row-hover {
      >.current-date {
        background-color: transparent !important;
      }
    }

  }

}

.ag-theme-material {
  background-color: var(--tblBg);
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, .2), 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 3px 0 rgba(0, 0, 0, .12);
  transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1);

  .ag-checkbox-input-wrapper {
    input {
      opacity: 30%;
    }

  }

  .ag-header {
    background-color: var(--tblHeaderBg) !important;
    border-bottom: none;
    color: var(--tblHeaderClr);

    font-weight: 600;
    font-size: 0.8rem;

    .ag-header-cell,
    .ag-header-group-cell {
      background-color: inherit;
      padding-left: 6px;
      padding-right: 0px;
    }

    .ag-header-cell-label .ag-header-icon.ag-sort-order {
      display: none
    }

    .ag-header-cell {
      &:first-child {
        border-left: none !important;
        padding-left: 20px;
      }

      .ag-header-icon {
        color: white;

        .ag-icon {
          color: inherit;
        }
      }

      .ag-sort-ascending-icon,
      .ag-sort-descending-icon {
        display: none;
      }
    }
  }

  .ag-cell {
    padding-right: 0px;
    padding-left: 6px;
  }

  .ag-cell {
    &:first-child {
      padding-left: 20px;
    }
  }

  .ag-row {
    border-color: transparent !important;

    &.ag-row-even {
      background-color: var(--evenBg);
      color: var(--evenClr);
    }

    &.ag-row-odd {
      background-color: var(--oddBg);
      color: var(--oddClr);
    }

    &.min-granularity-row {
      font-size: 105%;
      // border-top: 1px solid #FFD24D !important;
      // border-bottom: 1px solid #FFD24D !important;
    }

    &.summation-row-footer {
      background-color: #484747;
      color: var(--footerClr);
      font-weight: 600;

      &.ag-row-last {
        background-color: var(--tblHeaderBg);
        color: var(--tblHeaderClr);
      }

      >.current-date {
        background-color: transparent !important;
      }

      div[col-id="total"] {
        display: none;
      }
    }

    &:not(.ag-row-last) {
      >.current-date {
        background-color: var(--columnBg);
        //color: #FFBF00;
      }

      >.clickable-column-cells {
        cursor: pointer;
      }
    }
  }

  .ag-floating-bottom {
    border-top: none;
  }

  .ag-status-bar {
    border: none;
  }

  .ag-header-group-cell:not(.ag-column-resizing)+.ag-header-group-cell:hover,
  .ag-header-group-cell:not(.ag-column-resizing)+.ag-header-group-cell.ag-column-resizing,
  .ag-header-cell:not(.ag-column-resizing)+.ag-header-cell:hover,
  .ag-header-cell:not(.ag-column-resizing)+.ag-header-cell.ag-column-resizing,
  .ag-header-group-cell:first-of-type:hover,
  .ag-header-group-cell:first-of-type.ag-column-resizing,
  .ag-header-cell:first-of-type:hover,
  .ag-header-cell:first-of-type.ag-column-resizing {
    background-color: transparent;
  }
}

.light-theme {
  .ag-theme-material {
    .ag-row {
      &.ag-row-group {
        .ag-icon {
          color: #000080;
        }
      }
    }

    .ag-menu {
      .ag-icon-checkbox-checked {
        color: #444;
      }

      .ag-icon-checkbox-indeterminate,
      .ag-icon-checkbox-unchecked {
        background-color: transparent;
      }
    }
  }
}

.dark-theme {
  .ag-theme-material {
    .ag-row {
      &.ag-row-group {
        .ag-icon {
          color: #FFBF00;
        }
      }

      &.summation-row-footer {
        &.ag-row-last {
          background-color: #525050;
          color: var(--tblHeaderClr);
        }
      }

      .ag-cell:not(.ag-cell-focus).ag-cell-last-left-pinned:not(.ag-cell-range-right),
      .ag-cell.ag-cell-range-selected:not(.ag-cell-range-single-cell).ag-cell-last-left-pinned:not(.ag-cell-range-right),
      .ag-root:not(.ag-has-focus) .ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right) {
        border-right: 1px solid #444;
      }
    }

    .loading-indicator-container {
      .ag-overlay-loading-center {
        color: #FFF;
      }
    }

    .ag-menu {
      background: #444;
      color: #fff;

      .ag-tab-header {
        background: #000;
        color: inherit;

        .ag-icon {
          color: inherit;
        }
      }

      .ag-icon-checkbox-checked {
        color: #FFBF00;
      }

      .ag-icon-checkbox-indeterminate,
      .ag-icon-checkbox-unchecked {
        background-color: transparent;
      }
    }
  }
}